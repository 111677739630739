import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const Heartintune = () => {
  return (
    <div className="heartintune_wrapper">
      <StaticImage
        src="../../../static/special-event/Heartintune_BG.png"
        placeholder="blurred"
        alt="Daaji and his practitioners doing heartfulness meditation"
        className="main_image"
      />
      <div className="overlay">
        <h3>Master the habit of meditation</h3>
        <p>Download</p>
        <StaticImage
          className="logo"
          src="../../../static/special-event/heartintune_logo.png"
          placeholder="blurred"
          alt="Google play store"
        />
        <div className="d-flex my-3">
          <a
            href="https://play.google.com/store/apps/details?id=org.heartfulness.heartintune.prod"
            target="_blank"
            rel="noreferrer noopenerrer"
          >
            <StaticImage
              className="download me-3"
              src="../../../static/special-event/gplay.png"
              placeholder="blurred"
              alt="app store"
            />
          </a>
          <a
            href="https://apps.apple.com/ph/app/heart-in-tune/id1576857513"
            target="_blank"
            rel="noreferrer noopenerrer"
          >
            <StaticImage
              className="download"
              src="../../../static/special-event/ios-app.png"
              placeholder="blurred"
              alt="heartintune"
            />
          </a>
        </div>
        <StaticImage
          className="qrcode"
          src="../../../static/special-event/qrcode.png"
          placeholder="blurred"
          alt="heartintune"
        />
      </div>
    </div>
  );
};

export default Heartintune;
