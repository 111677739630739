import React from "react";
import useEventInfo from "gatsby-plugin-hfn-profile/components/PnrSummary/useEventInfo";

import HomeBanner from "../components/home/banner/index";
import Discover from "../components/home/discover/index";
import KanhaLifeSection from "../components/home/kanha-life/index";
import KanhaNewsSection from "../components/home/kanha-news/index";
import KanhaFeedSection from "../components/home/kanha-feed/index";
import Seo from "../components/seo/index";
import Layout from "../layout/index";
import Heartintune from "../components/special-event/Heartintune";
import "../assets/styles/components/home.scss";
import { februaryBhandaraEventId } from "../assets/data/bandaraRegistration";

const IndexPage = () => {
  const { data } = useEventInfo(februaryBhandaraEventId);

  return (
    <>
      <Seo title="Kanha Shanti Vanam" />
      <Layout isSticky wrapperClass="home">
        <div>
          <HomeBanner eventId={februaryBhandaraEventId} data={data || {}} />
          <Discover />
          <KanhaLifeSection />
          <KanhaNewsSection />
          <Heartintune />
          <KanhaFeedSection />
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
