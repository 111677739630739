import React, { useState, useEffect, useCallback } from "react";

import axios from "axios";
import getMandatoryEnv from "../../../../../sites-common/utils/getMandatoryEnv";
// import instaFeed from "../../../assets/data/insta";

const KanhaFeedSection = () => {
  const { kanhaAPIConfig } = getMandatoryEnv(["kanhaAPIConfig"]);
  const [galleryData, setGalleryData] = useState();

  const instagalleryGetData = useCallback(() => {
    axios
      .get(`${kanhaAPIConfig.formUrl}/api/v1/data/?module=instagallery`)
      .then((res) => {
        setGalleryData(res.data?.data);
      });
  }, [kanhaAPIConfig.formUrl]);

  useEffect(() => {
    instagalleryGetData();
  }, [instagalleryGetData]);

  return (
    <div className="kanha-feed-wrapper pt-3">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <a
                href="https://www.instagram.com/kanhashantivanam/"
                target="_blank"
                rel="noopener noreferrer"
              >
                @kanhashantivanam
              </a>
            </div>
          </div>
          {galleryData &&
            galleryData
              ?.sort((a, b) => a.sort_order - b.sort_order)
              .slice(0, 8)
              .map((item) => {
                return (
                  <div className="col-md-3" key={item?.sort_order}>
                    {/* <div className="img-wrapper">{item.img}</div> */}
                    <div className="img-wrapper">
                      <img src={item?.image} alt="instagram" />
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default KanhaFeedSection;
