import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";

const CoundownTimer = (props) => {
  const {
    eventStartingDate,
    eventStartingTime,
    fontColor,
    contrastColor,
    isMobile,
  } = props;

  const calculateTimeLeft = () => {
    const eventStartDate = eventStartingDate?.split("/");
    const newEventStartDate = `${eventStartDate[2]}-${eventStartDate[1]}-${eventStartDate[0]}`;
    const currentDate = moment(new Date()).format("L")?.split("/");
    const newCurrentDay = `${currentDate[2]}-${currentDate[0]}-${currentDate[1]}`;
    const difference =
      +new Date(
        `${
          eventStartingDate?.includes("/") === true
            ? newEventStartDate
            : newCurrentDay
        } ${eventStartingTime}`
      ) - +new Date().getTime();
    let timeLeftObj = {};

    if (difference > 0) {
      timeLeftObj = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
      };
    }

    return timeLeftObj;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  return (
    <>
      {!isMobile && Object.keys(timeLeft)?.length !== 0 && (
        <span style={{ fontSize: "12px" }}>
          <b>Begins In</b>
        </span>
      )}
      <br />
      <div
        className="countdown-timer-wrapper"
        style={{
          maxWidth: !isMobile ? "20%" : "",
          display: "flex",
          justifyContent: !isMobile ? "" : "center",
          flexBasis: "0",
          flexGrow: "1",
          textAlign: "center",
          position: !isMobile ? "" : "relative",
          top: !isMobile ? "" : "12.5rem",
          fontSize: isMobile ? "12px" : "",
        }}
      >
        {" "}
        {isMobile && Object.keys(timeLeft)?.length !== 0 && (
          <span style={{ fontWeight: "400" }}>
            <b style={{ marginRight: "5px" }}>Begins In</b>
          </span>
        )}
        <div className="col-lg-12" style={{ display: "flex" }}>
          {!isMobile && (
            <>
              {timeLeft?.days !== 0 && Object.keys(timeLeft)?.length !== 0 && (
                <>
                  <div
                    className="home-banner-countdown-item"
                    style={{
                      textAlign: "center",
                      color: fontColor,
                    }}
                  >
                    <span
                      className="wsc-countdown-digits"
                      style={{
                        backgroundColor: fontColor,
                        marginRight: "calc( 10px/2 )",
                        padding: "10px 10px",
                        fontSize: "25px",
                        lineHeight: "1",
                        fontWeight: "600",
                        display: "block",
                        borderRadius: "5px",
                        color: contrastColor,
                      }}
                    >
                      {timeLeft?.days}
                    </span>
                    <span
                      className="wsc-countdown-label"
                      style={{
                        fontSize: "12px",
                        lineHeight: "1",
                        textAlign: "center",
                        color: fontColor,
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      Days
                    </span>{" "}
                  </div>
                  {(timeLeft?.hours !== 0 || timeLeft?.minutes !== 0) && (
                    <p className="counter_colon"> : </p>
                  )}
                </>
              )}
              {timeLeft?.hours !== 0 && Object.keys(timeLeft)?.length !== 0 && (
                <>
                  <div
                    className="home-banner-countdown-item"
                    style={{
                      textAlign: "center",
                      color: fontColor,
                    }}
                  >
                    <span
                      className="wsc-countdown-digits"
                      style={{
                        backgroundColor: fontColor,
                        marginRight: "calc( 10px/2 )",
                        padding: "10px 10px",
                        fontSize: "25px",
                        lineHeight: "1",
                        fontWeight: "600",
                        display: "block",
                        borderRadius: "5px",
                        color: contrastColor,
                      }}
                    >
                      {timeLeft?.hours}
                    </span>
                    <span
                      className="wsc-countdown-label"
                      style={{
                        fontSize: "12px",
                        lineHeight: "1",
                        textAlign: "center",
                        color: fontColor,
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      Hours
                    </span>{" "}
                  </div>
                  {timeLeft?.minutes !== 0 && (
                    <p className="counter_colon"> : </p>
                  )}
                </>
              )}
              {timeLeft?.minutes !== 0 && Object.keys(timeLeft)?.length !== 0 && (
                <>
                  <div
                    className="home-banner-countdown-item"
                    style={{
                      textAlign: "center",
                      color: fontColor,
                    }}
                  >
                    <span
                      className="wsc-countdown-digits"
                      style={{
                        backgroundColor: fontColor,
                        marginRight: "calc( 10px/2 )",
                        padding: "10px 10px",
                        fontSize: "25px",
                        lineHeight: "1",
                        fontWeight: "600",
                        display: "block",
                        borderRadius: "5px",
                        color: contrastColor,
                      }}
                    >
                      {timeLeft?.minutes}
                    </span>
                    <span
                      className="wsc-countdown-label"
                      style={{
                        fontSize: "12px",
                        lineHeight: "1",
                        textAlign: "center",
                        color: fontColor,
                        fontWeight: "600",
                        textTransform: "uppercase",
                      }}
                    >
                      Minutes
                    </span>{" "}
                  </div>
                </>
              )}
            </>
          )}
          {isMobile && (
            <>
              {timeLeft?.days !== 0 && Object.keys(timeLeft)?.length !== 0 && (
                <>
                  <div
                    className="home-banner-countdown-item pl-1"
                    style={{
                      textAlign: "center",
                      color: fontColor,
                      fontWeight: "400",
                    }}
                  >
                    <span
                      className="wsc-countdown-digits ml-2"
                      style={{
                        color: fontColor,
                      }}
                    >
                      <b>{timeLeft?.days}</b>{" "}
                    </span>
                    <span
                      className="wsc-countdown-label"
                      style={{
                        lineHeight: "1",
                        textAlign: "center",
                        color: fontColor,
                        fontWeight: "400",
                        textTransform: "lowercase",
                      }}
                    >
                      <b>Days</b>
                    </span>{" "}
                  </div>
                  {(timeLeft?.hours !== 0 || timeLeft?.minutes !== 0) && (
                    <p
                      className="counter_colon"
                      style={{ color: fontColor, fontSize: "20px" }}
                    >
                      ,
                    </p>
                  )}
                </>
              )}
              {timeLeft?.hours !== 0 && Object.keys(timeLeft)?.length !== 0 && (
                <>
                  <div
                    className="home-banner-countdown-item px-1"
                    style={{
                      textAlign: "center",
                      color: fontColor,
                      fontWeight: "400",
                    }}
                  >
                    <span
                      className="wsc-countdown-digits"
                      style={{
                        color: fontColor,
                      }}
                    >
                      <b>{timeLeft?.hours}</b>{" "}
                    </span>
                    <span
                      className="wsc-countdown-label"
                      style={{
                        lineHeight: "1",
                        textAlign: "center",
                        color: fontColor,
                        fontWeight: "400",
                        textTransform: "lowercase",
                      }}
                    >
                      <b>Hours</b>
                    </span>{" "}
                  </div>
                  {timeLeft?.minutes !== 0 && (
                    <p
                      className="counter_and_text"
                      style={{ color: fontColor }}
                    >
                      <b>and</b>
                    </p>
                  )}
                </>
              )}
              {timeLeft?.minutes !== 0 && Object.keys(timeLeft)?.length !== 0 && (
                <>
                  <div
                    className="home-banner-countdown-item px-1"
                    style={{
                      textAlign: "center",
                      color: fontColor,
                      fontWeight: "400",
                    }}
                  >
                    <span
                      className="wsc-countdown-digits"
                      style={{
                        color: fontColor,
                      }}
                    >
                      <b>{timeLeft?.minutes}</b>{" "}
                    </span>
                    <span
                      className="wsc-countdown-label"
                      style={{
                        lineHeight: "1",
                        textAlign: "center",
                        color: fontColor,
                        fontWeight: "400",
                        textTransform: "lowercase",
                      }}
                    >
                      <b>Minutes</b>
                    </span>{" "}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

CoundownTimer.propTypes = {
  eventStartingDate: PropTypes.string.isRequired,
  eventStartingTime: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  contrastColor: PropTypes.string.isRequired,
};

export default CoundownTimer;
