import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image";
import useCommonData from "./CommonData/commonData";
import CoundownTimer from "./coundownTimer";

const HomeBanner = () => {
  const { bannerData, loading } = useCommonData();
  const slider = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    speed: 500,
    autoplaySpeed: 5000,
  };

  const showBtn = (data) => {
    const currDate = new Date();
    const eventStartDate = data?.Event_Start_Date?.split("/");
    const RegStartDate = data?.Reg_start_dt?.split("/");
    const RegEndDate = data?.Reg_end_date?.split("/");
    const newEventStartDate = new Date(
      `${eventStartDate[2]}-${eventStartDate[1]}-${eventStartDate[0]}`
    ).setHours(0, 0, 0, 0);
    const newRegStartData = new Date(
      `${RegStartDate[2]}-${RegStartDate[1]}-${RegStartDate[0]}`
    ).setHours(0, 0, 0, 0);
    const newRegEndData = new Date(
      `${RegEndDate[2]}-${RegEndDate[1]}-${RegEndDate[0]}`
    ).setHours(0, 0, 0, 0);

    const isRegisterActive =
      newRegStartData <= currDate && currDate <= newRegEndData;

    return (
      <>
        {newEventStartDate > currDate &&
          newRegStartData > currDate &&
          !isRegisterActive && (
            <div
              className={!isMobile ? "mt-5 register_btn" : "register_btn"}
              style={{
                backgroundColor: "grey",
                border: "1px solid black",
                minWidth: isMobile ? "230px" : "230px",
              }}
            >
              <span
                className="banner_btn_text"
                style={{
                  color: "#FFFFFF",
                  textTransform: "capitalize",
                }}
              >
                Registrations Opening soon
              </span>
            </div>
          )}
        {isRegisterActive && (
          <div
            className={!isMobile ? "mt-5 register_btn" : "register_btn"}
            style={{
              backgroundColor: data?.Font_Colour,
              minWidth: isMobile ? "170px" : "",
            }}
          >
            <a
              className=""
              href={data.Register_Link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className="banner_btn_text"
                style={{
                  color: data?.Contrast_Colour,
                  minWidth: isMobile ? "170px" : "",
                  textTransform: "capitalize",
                }}
              >
                Register Now
              </span>
            </a>
          </div>
        )}
        {newRegEndData < currDate && !isRegisterActive && (
          <div
            className={!isMobile ? "mt-5 register_btn" : "register_btn"}
            style={{ backgroundColor: "grey", border: "1px solid black" }}
          >
            <span
              className="banner_btn_text"
              style={{
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
            >
              Registration Cosed
            </span>
          </div>
        )}
      </>
    );
  };

  const mobileScreenChanger = () => {
    return window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  };
  useEffect(() => {
    mobileScreenChanger();
  }, []);

  return (
    <div className="new-banner">
      {loading && (
        <div className="loader_banner">
          <StaticImage
            src="../../../../static/home-screen/home_banner_2-home.png"
            placeholder="blurred"
            alt="banner image"
            className="dynamic-image"
          />
          <div className="loader_content">
            <h1 className="announcement_title1">Meditation Hall</h1>
            <h3 className="announcement_title1">Kanha Shanti Vanam</h3>
          </div>
        </div>
      )}
      {!loading && bannerData?.length >= 1 && (
        <div className="home_banner_slider">
          <div className="slider_conatiner">
            <Slider ref={slider} {...settings}>
              {bannerData?.length >= 1 &&
                bannerData?.map((item) => {
                  return (
                    <div
                      key={item?.Announcement_title}
                      className={"bandara_banner_style position-bottom" || " "}
                    >
                      <img
                        src={
                          isMobile
                            ? item?.Mobile_Banner_Image
                            : item?.Web_Banner_Image
                        }
                        alt=""
                        className="dynamic-image"
                      />
                      {!isMobile && (
                        <div
                          className="home_banner_content"
                          style={{
                            color: item?.Font_Colour,
                          }}
                        >
                          <div className="time-venue-conatiner">
                            <ul className="" style={{ paddingLeft: "0px" }}>
                              {item?.Text_1 && (
                                <li style={{ listStyle: "none" }}>
                                  {item?.Text_1} |{" "}
                                  {item?.Venue && (
                                    <span className="">{item?.Venue}</span>
                                  )}
                                </li>
                              )}
                            </ul>
                          </div>
                          <div className="announcement_title_wrapper">
                            <h1
                              className="announcement_title1"
                              dangerouslySetInnerHTML={{
                                __html: `${item?.Announcement_title}`,
                              }}
                            />
                          </div>
                          <div style={{ width: "385px" }}>
                            <ul
                              style={{ paddingLeft: "0px" }}
                              className="text_2"
                            >
                              {item?.Text_2 && (
                                <li
                                  style={{ listStyle: "none" }}
                                  dangerouslySetInnerHTML={{
                                    __html: `${item?.Text_2}`,
                                  }}
                                />
                              )}
                            </ul>
                          </div>

                          {item?.Register_Link ? (
                            <>{showBtn(item)}</>
                          ) : (
                            <div className="mt-5 register_btn" />
                          )}
                          <br />
                          {item?.Event_Start_time && (
                            <div className="timer_wrapper">
                              <div className="countdown-wrapper ">
                                <CoundownTimer
                                  eventStartingDate={item?.Event_Start_Date}
                                  eventStartingTime={item?.Event_Start_time}
                                  fontColor={item?.Font_Colour}
                                  contrastColor={item?.Contrast_Colour}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {isMobile && (
                        <div
                          className="mobile_home_banner_content"
                          style={{
                            color: item?.Font_Colour,
                          }}
                        >
                          <div className="time-venue-conatiner">
                            <ul className="" style={{ paddingLeft: "0px" }}>
                              {item?.Text_1 && (
                                <li style={{ listStyle: "none" }}>
                                  {item?.Text_1}
                                </li>
                              )}
                            </ul>
                          </div>
                          <div className="announcement_title_wrapper">
                            <h1
                              className="announcement_title1"
                              dangerouslySetInnerHTML={{
                                __html: `${item?.Announcement_title}`,
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              position: "relative",
                              top: "1em",
                            }}
                          >
                            <ul
                              style={{
                                paddingLeft: "0px",
                                fontSize: isMobile ? "12px" : "",
                                fontWeight: isMobile ? "400" : "",
                                width: "226px",
                              }}
                              className="text_2"
                            >
                              {item?.Text_2 && (
                                <li
                                  style={{ listStyle: "none" }}
                                  dangerouslySetInnerHTML={{
                                    __html: `${item?.Text_2}`,
                                  }}
                                />
                              )}
                            </ul>
                          </div>
                          {item?.Register_Link ? (
                            <div
                              className="show_btn_wrapper"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                position: "relative",
                                top: "14rem",
                              }}
                            >
                              {showBtn(item)}
                            </div>
                          ) : (
                            <div className="register_btn" />
                          )}
                          <br />
                          {item?.Event_Start_time && (
                            <div className="timer_wrapper">
                              <div className="countdown-wrapper ">
                                <CoundownTimer
                                  eventStartingDate={item?.Event_Start_Date}
                                  eventStartingTime={item?.Event_Start_time}
                                  fontColor={item?.Font_Colour}
                                  contrastColor={item?.Contrast_Colour}
                                  isMobile={isMobile}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
            </Slider>
            <div className="carousel-button-group">
              <div className="navigations">
                <button
                  type="button"
                  onClick={() => slider.current.slickPrev()}
                  className="me-3"
                >
                  <i className="icon-chevron-left" />
                </button>
                <button
                  type="button"
                  onClick={() => slider.current.slickNext()}
                >
                  <i className="icon-chevron-right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// HomeBanner.propTypes = {
//   endDate: PropTypes.bool.isRequired,
//   eventId: PropTypes.string.isRequired,
//   data: PropTypes.shape({
//     reg_starts: PropTypes.string,
//     reg_ends: PropTypes.string,
//     end_datetime: PropTypes.string,
//     event_json: PropTypes.shape({
//       custom_config_json: PropTypes.string,
//     }),
//   }).isRequired,
// };

export default HomeBanner;
