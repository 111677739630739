import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { useQueryParam, StringParam } from "use-query-params";
import responsive from "../../../assets/data/sliderConfig";
import ArrowButtons from "../../../shared/CarouselArrow";
// import Booknowsearch from "../../../shared/BookNowSearch";

const Discover = () => {
  const [popup, setPopup] = useState(false);
  // const [text, showText] = useState(false);

  const [action] = useQueryParam("action", StringParam);
  useEffect(() => {
    if (action && action === "journey") {
      setPopup(true);
    }
  }, [action]);

  return (
    <div className="discover">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {/* <div className="book-a-stay-wrapper">
              <div className="book-a-stay">
                <button className="btn btn-info book-a-stay-btn" type="button">
                  Book a stay
                </button>
                <Booknowsearch />
              </div>
            </div> */}
            <div className="best-of-kanha">
              <div className="title-section">
                <h3 className="title">Discover kanha</h3>
                <p className="des">Explore the Uniqueness of Kanha Village</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 img-wrapper">
            <div className="img-section">
              {/* <StaticImage
                src="../../../assets/images/expJourney.jpg"
                alt="Yatra Garden"
                placeholder="blurred"
              /> */}
              <StaticImage
                placeholder="blurred"
                src="../../../../static/meditation.jpg"
                alt="meditation"
              />
              {/* <img src="../../meditation.jpg" alt="meditation" /> */}

              <div className="overlay">
                <p>
                  Situated by the Vivekananda Marg, sheltered amidst the silence
                  of trees, and surrounded by the inscriptions of sacred
                  scriptures, Kanha Shanti Vanam nurtures oneness with self and
                  with others.
                </p>
                <div className="btn_wrapper">
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      navigate("service/#meditation-hall");
                    }}
                  >
                    Know more
                  </button>
                </div>
                <h4>
                  Experience <br />
                  <span>Meditation</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 img-wrapper">
            <div className="img-section">
              <StaticImage
                placeholder="blurred"
                src="../../../../static/expJourney.png"
                alt="meditation"
              />
              <div className="overlay">
                <p>
                  The Yatra Garden is a symbolic representation of the spiritual
                  journey undertaken by a seeker of God. This inner yatra of the
                  soul, and its mysteries, reflect the unique spiritual heritage
                  of Heartfulness.
                </p>

                <div className="btn_wrapper">
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      setPopup(true);
                    }}
                  >
                    Know more
                  </button>
                </div>
                <h4>
                  Experience <br /> <span>the Journey</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-4 img-wrapper">
            <div className="img-section">
              <StaticImage
                placeholder="blurred"
                src="../../../../static/expRetreat.png"
                alt="meditation"
              />
              <div className="overlay">
                <p>
                  In tune with nature, and equipped with modern amenities, the
                  Wellness Center at Kanha offers therapeutic healing systems,
                  such as Ayurveda and Panchakarma, all under one roof.
                </p>
                <div className="btn_wrapper">
                  <button
                    type="button"
                    className="btn"
                    onClick={() => navigate("service/#wellness-center")}
                  >
                    Know more
                  </button>
                </div>
                <h4>
                  Experience <br />
                  <span>the Retreat</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="discover-kanha"
          show={popup}
          onHide={() => setPopup(false)}
          centered
        >
          <Modal.Body>
            <div className="image-popup-box">
              <span
                className="close"
                aria-label="Close"
                tabIndex={0}
                role="button"
                onKeyDown={() => setPopup(false)}
                onClick={() => setPopup(false)}
              />

              <div className="image_popup_content">
                <div className="img_flex_shrink">
                  <Carousel
                    arrows={false}
                    autoPlaySpeed={3000}
                    draggable
                    autoPlay
                    infinite
                    keyBoardControl
                    minimumTouchDrag={80}
                    partialVisible
                    renderButtonGroupOutside
                    swipeable
                    responsive={responsive}
                    customButtonGroup={<ArrowButtons />}
                  >
                    <StaticImage
                      src="../../../assets/images/exp1.jpg"
                      alt="experience retreat"
                      placeholder="blurred"
                    />
                    <StaticImage
                      src="../../../assets/images/exp2.jpg"
                      alt="experience retreat"
                      placeholder="blurred"
                    />
                    <StaticImage
                      src="../../../assets/images/exp3.jpg"
                      alt="experience retreat"
                      placeholder="blurred"
                    />
                  </Carousel>
                </div>
                <div className="popup_sideview">
                  <h2>EXPERIENCE THE JOURNEY</h2>
                  <p>
                    The Yatra Garden is a place of silent contemplation and
                    reflection, where you spend time in a series of beautiful
                    gardens that have been designed in the form of a journey.
                    This journey, the Yatra, represents the spiritual journey
                    through the chakras of energy in the human system. As you
                    walk from one habitat to the next, you will experience
                    first-hand the various qualities of each of the chakras. In
                    every garden you will find benches and sitting areas so that
                    you may contemplate, meditate, read and absorb the various
                    conditions of each stage on the journey.
                  </p>
                  <p>
                    You are welcome to spend time in each of the chakra gardens,
                    so as to imbibe the atmosphere of the place, and learn more
                    about the qualities.
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Discover;
