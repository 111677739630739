import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Carousel from "react-multi-carousel";
import { Modal } from "react-bootstrap";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import responsive from "../../../assets/data/sliderConfig";
import ArrowButtons from "../../../shared/CarouselArrow";

const KanhaLifeSection = ({ showKanha }) => {
  const [popup, setPopup] = useState(false);
  const [awardPopUp, setAwardPopUp] = useState(false);
  const [awardPop2Up, setAwardPop2Up] = useState(false);
  const popupImages = {
    0: [
      <StaticImage
        placeholder="blurred"
        src="../../../../static/Award_1/haritia_5.png"
        alt="slider"
      />,
      <StaticImage
        placeholder="blurred"
        src="../../../../static/Award_1/hartiharam_4.png"
        alt="slider"
      />,
      <StaticImage
        placeholder="blurred"
        src="../../../../static/Award_1/hartiharam_1.png"
        alt="slider"
      />,
      <StaticImage
        placeholder="blurred"
        src="../../../../static/Award_1/hartiharam_2.png"
        alt="slider"
      />,
      <StaticImage
        placeholder="blurred"
        src="../../../../static/Award_1/Image 2.png"
        alt="slider"
      />,
    ],
    1: [
      <StaticImage
        placeholder="blurred"
        src="../../../../static/Award_1/igbc_1.png"
        alt="slider"
      />,
      <StaticImage
        placeholder="blurred"
        src="../../../../static/Award_1/igbc_2.png"
        alt="slider"
      />,
    ],
  };
  return (
    <div className="kanha-life-wrapper section-wrapper">
      <h3 className="section-title info-text text-uppercase">
        Kanha Shanti Vanam Inaguration
      </h3>
      <div className="kanha-life-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="content"
                role="button"
                tabIndex={0}
                onClick={() => setPopup(true)}
                onKeyDown={() => setPopup(true)}
              >
                <StaticImage
                  src="../../../assets/images/video_img.jpg"
                  alt="Upcomming"
                  placeholder="blurred"
                />
                <StaticImage
                  placeholder="blurred"
                  className="play"
                  src="../../../../static/play.png"
                  alt="play"
                />
              </div>
            </div>
          </div>
          <Modal
            className="full-width-video-popup"
            show={popup}
            onHide={() => setPopup(false)}
            centered
          >
            <Modal.Body>
              <div
                className="video_popup_close"
                role="button"
                tabIndex={0}
                onClick={() => setPopup(false)}
                onKeyDown={() => setPopup(false)}
              >
                <div className="close" />
              </div>
              <div className="video_popup_wrapper">
                <iframe
                  src="https://player.vimeo.com/video/682174387?h=df4d063a8b"
                  allowFullScreen="allowfullscreen"
                  loading="lazy"
                  allow="autoplay; fullscreen; picture-in-picture"
                  title="LIVE THE KANHA LIFE"
                />
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            className="services-popup"
            show={awardPopUp}
            onHide={() => setAwardPopUp(false)}
            centered
          >
            <Modal.Body>
              <div className="services-popup-box medical">
                <span
                  className="close"
                  tabIndex={0}
                  role="button"
                  onKeyDown={() => setAwardPopUp(false)}
                  onClick={() => setAwardPopUp(false)}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span>
                <div className="image_popup_content">
                  <div className="image_slider">
                    <Carousel
                      arrows={false}
                      autoPlaySpeed={3000}
                      draggable
                      autoPlay
                      infinite
                      keyBoardControl
                      minimumTouchDrag={80}
                      partialVisible
                      renderButtonGroupOutside
                      swipeable
                      responsive={responsive}
                      customButtonGroup={<ArrowButtons />}
                    >
                      {popupImages[1].map((item) => item)}
                    </Carousel>
                  </div>
                  <div className="popup_sideview">
                    <h2>IGBC PLATINUM AWARD 2019</h2>
                    <p>
                      The Confederation of Indian Industry (CII) works to create
                      and sustain an environment conducive to the development of
                      India, partnering industry, Government, and civil society,
                      through advisory and consultative processes. The CII has
                      founded the IGBC rating system to meet the environmental
                      standards for green construction and campuses across
                      India. Indian Green Building Council (IGBC) is the first
                      rating programme developed in India, exclusively for the
                      residential and building sectors. It is based on accepted
                      energy and environmental principles and strikes a balance
                      between known established practices and emerging concepts.
                    </p>
                    <b>
                      Kanha Shanti Vanam, was awarded the prestigious Platinum
                      IGBC award for 2019. This is worth 80-100 credits in the
                      rating system.
                    </b>
                    <p>
                      Kanha Shanti Vanam, was awarded the prestigious Platinum
                      IGBC award for 2019. This is worth 80-100 credits in the
                      rating system.
                    </p>
                    <ul>
                      <li>Sustainable Transportation</li>
                      <li>Water Conservation</li>
                      <li>Energy Efficiency</li>
                      <li>Material and Resource Management</li>
                      <li>Health & Well-being.</li>
                      <li>Green Education </li>
                      <li>Innovation in Design.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            className="services-popup"
            show={awardPop2Up}
            onHide={() => setAwardPop2Up(false)}
            centered
          >
            <Modal.Body>
              <div className="services-popup-box medical">
                <span
                  className="close"
                  tabIndex={0}
                  role="button"
                  onKeyDown={() => setAwardPop2Up(false)}
                  onClick={() => setAwardPop2Up(false)}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span>
                <div className="image_popup_content">
                  <div className="image_slider">
                    <Carousel
                      arrows={false}
                      autoPlaySpeed={3000}
                      draggable
                      autoPlay
                      infinite
                      keyBoardControl
                      minimumTouchDrag={80}
                      partialVisible
                      renderButtonGroupOutside
                      swipeable
                      responsive={responsive}
                      customButtonGroup={<ArrowButtons />}
                    >
                      {popupImages[0].map((item) => item)}
                    </Carousel>
                  </div>
                  <div className="popup_sideview">
                    <h2>Haritha Haram</h2>
                    <p>
                      &apos;Haritha Haram&apos;, meaning the Green Garland, is a
                      flagship programme of the Telangana State Government, with
                      the vision to increase the present 24% tree cover in the
                      state to 33% of the total geographic area. It was formally
                      launched by the Chief Minister, Honourable Shri
                      Chandrashekar Rao on 3 July 2015. In line with the
                      objective of the govt, SMSF have planted over 5,00,000
                      trees in and around our ashram. The 100,000th plant was
                      planted by Honourable president of India, Mr. Ram Nath
                      Kovind. In recognition of exemplary contribution to this
                      ambitious greening movement outside the notified forest
                      areas of the state, Shri Ram Chandra Mission, Kanha
                      Shantivanam, Ranga Reddy District, was awarded the
                      prestigious &lsquo;Haritha Mitra&rsquo; (Green Friend)
                      award for the year 2016 and 2019.
                    </p>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {showKanha && (
            <div className="row kanha-info-wrpper">
              <div className="col-12 col-md-5 green-kanha kanha-info overlay-panel">
                <div className="content">
                  <h3>GREEN KANHA</h3>
                  <p>
                    At Kanha, nature exists in awe-inspiring stillness and
                    elegance. Kanha is a space where humankind and nature
                    coexist in harmony.
                  </p>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => navigate("/green-initiatives")}
                  >
                    VIEW SITE
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-5 blue-kanha kanha-info overlay-panel">
                <div className="content">
                  <h3>BLUE KANHA</h3>
                  <p>
                    Water conserved today is a promise for tomorrow. Kanha
                    offers facilities that preserve, protect, and manage this
                    precious resource in a sustainable way.
                  </p>
                  <button
                    onClick={() => navigate("/blue-initiatives")}
                    type="button"
                    className="btn btn-info"
                  >
                    VIEW SITE
                  </button>
                </div>
              </div>
              <div
                className="col-12 col-md-12 col-lg-2"
                style={{ padding: "0px" }}
              >
                <div className="content award">
                  <h4 className="text-center awards-section">
                    AWARDS AND RECOGINITION
                  </h4>
                  <div className="image-text-container">
                    <div className="text-center">
                      <StaticImage
                        src="../../../../static/Award_1/award_2.png"
                        alt="Dr. Kishore Sabbu"
                        className="img-fluid"
                        style={{ maxWidth: "150px", height: "150px" }}
                        onClick={() => setAwardPopUp(true)}
                        onKeyDown={() => setAwardPopUp(true)}
                      />
                      <p className="mx-2">IGBC PLATINUM AWARD 2019</p>
                    </div>
                    <div className="text-center">
                      <StaticImage
                        src="../../../../static/Award_1/award_small.png"
                        alt="Dr. Kishore Sabbu"
                        className="img-fluid"
                        style={{ maxWidth: "150px", height: "150px" }}
                        onClick={() => setAwardPop2Up(true)}
                        onKeyDown={() => setAwardPop2Up(true)}
                      />
                      <p className="mx-2">HARITHA HARAM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

KanhaLifeSection.defaultProps = {
  showKanha: true,
};

KanhaLifeSection.propTypes = {
  showKanha: PropTypes.bool,
};

export default KanhaLifeSection;
