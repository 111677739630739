import React, { useState, useEffect, useCallback } from "react";

import Carousel from "react-multi-carousel";
import axios from "axios";
import getMandatoryEnv from "../../../../../sites-common/utils/getMandatoryEnv";
// import { newsData } from "../../../assets/data/sliderConfig";
import ArrowButtons from "../../../shared/CarouselArrow";

const KanhaNewsSection = () => {
  const { kanhaAPIConfig } = getMandatoryEnv(["kanhaAPIConfig"]);
  const [newsData, setNewsData] = useState([]);

  const newsGetData = useCallback(() => {
    axios
      .get(`${kanhaAPIConfig.formUrl}/api/v1/data/?module=news`)
      .then((res) => {
        setNewsData(res.data?.data);
      });
  }, [kanhaAPIConfig.formUrl]);

  useEffect(() => {
    newsGetData();
  }, [newsGetData]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="kanha-news-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-md-12 kanha-news-section">
            <div className="section-title">
              <h3>Kanha in the NEWS</h3>
            </div>
            <div className="row">
              <Carousel
                arrows={false}
                autoPlaySpeed={2000}
                draggable
                infinite
                autoPlay={false}
                keyBoardControl
                minimumTouchDrag={80}
                partialVisible
                renderButtonGroupOutside
                swipeable
                responsive={responsive}
                customButtonGroup={<ArrowButtons />}
              >
                {newsData &&
                  newsData?.map((item) => (
                    <div className="kanha-news" key={item?.title}>
                      <a
                        href={item?.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="content">
                          <img src={item?.thumbnail} alt="slider" />
                          <h4>
                            {`${item?.title.slice(0, 60)} ${
                              item?.title.length > 60 ? "..." : ""
                            }`}
                          </h4>
                          <div className="time-stamp">
                            <span className="date">{item?.published_on}</span>
                            <img src={item?.logo} alt="logo" />
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
              </Carousel>
              {/* <div className="col-md-3 kanha-news">
                <div className="content">
                  <img src="../../kanha-news/kanha-santhi.png" alt="trees" />
                  <h4>
                    Kanha Shanti Vanam for city-weary souls seeking spirituality
                  </h4>
                  <div className="time-stamp">
                    <span className="date">29th November 2017</span>
                    <img
                      src="../../logos/indian-express.png"
                      alt="times-of-india"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3 kanha-news">
                <div className="content">
                  <img src="../../kanha-news/telungana-trees.png" alt="trees" />
                  <h4>
                    A Telangana &lsquo;Hospital&lsquo; That Gives Dying Trees A
                    Second Life
                  </h4>
                  <div className="time-stamp">
                    <span className="date">29th November 2017</span>
                    <img src="../../logos/ndtv.png" alt="times-of-india" />
                  </div>
                </div>
              </div>
              <div className="col-md-3 kanha-news">
                <div className="content">
                  <img
                    src="../../kanha-news/meditation-hall-hyderabad.png"
                    alt="trees"
                  />
                  <h4>
                    World Heartfulness Centre- Kanha Shanti Vanam, Hyderabad
                  </h4>
                  <div className="time-stamp">
                    <span className="date">17th September 2017</span>
                    <img
                      src="../../logos/creators-architects.png"
                      alt="times-of-india"
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KanhaNewsSection;
